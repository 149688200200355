import colors from "styles/colors";
import styled from "@emotion/styled";
import Constants from "../constants";
const TAB_HEIGHT = `${Constants.TAB_HEIGHT}px`;
export const StyledTab = styled.div`
  height: ${TAB_HEIGHT};
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.875rem;

  &.active {
    color: ${({ color })=>colors[color]};

    &.with-image {
      border-left: 5px solid;
      border-color: var(--border-color);
    }
  }

  :hover {
    color: ${({ color })=>colors[color]};
    outline: 0;
  }

  &:first-child {
    .vertical-tab-timeline {
      border-radius: 5px 5px 0px 0px;
    }
  }

  &:last-child {
    .vertical-tab-timeline {
      border-radius: 0px 0px 5px 5px;
    }
  }
`;
