import styled from "@emotion/styled";
export const StyledVerticalTabs = styled.div`
  .desktop-wrapper {
    display: block;
  }

  .mobile-wrapper {
    display: none;
  }

  @media (max-width: 991px) {
    .desktop-wrapper {
      display: none;
    }

    .mobile-wrapper {
      display: block;
    }
  }
`;
