import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
import styled from "@emotion/styled";
export const StyledVerticalTabsMobileComponent = styled.div`
  max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
  margin: auto;
  padding: 0px 24px;

  .vertical-tabs-mobile-title-wrapper {
    padding: 0px 16px;
    margin-bottom: 32px;
    text-align: center;
  }

  .tab-wrapper {
    margin-bottom: 80px;

    .tab-label {
      font-size: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .line {
        height: 2px;
        flex-grow: 1;
      }

      .text {
        flex-shrink: 0;
        margin: 0px 16px;
        font-weight: 400;
      }
    }

    .tab-content {
      margin-top: 32px;

      .paragraph-wrapper .paragraph-body {
        display: none !important;
      }

      .side-by-side-text-and-asset {
        .side-by-side-content {
          background-color: transparent !important;
        }
      }
    }
  }
`;
