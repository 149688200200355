import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
import styled from "@emotion/styled";
export const StyledVerticalTabsDesktopComponent = styled.div`
  max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
  margin: auto;
  padding-left: 32px;

  .vertical-tabs-title-wrapper {
    padding: 32px 48px;
    text-align: center;
  }

  .side-by-side-text-and-asset {
    .full-paragraph-component-wrapper {
      align-self: ${({ positionStyles })=>(positionStyles === null || positionStyles === void 0 ? void 0 : positionStyles.alignItems) || "auto"};
      width: 40%;
    }

    .image-wrapper {
      width: 60%;
    }
  }
`;
